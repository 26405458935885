import React, { useState } from "react";
import Layout from "../components/Layout";
import { Head } from "../components/Header/Head";


import './contact.scss'


const Contact = () => {

  const [color, setColor] = useState('#6e57e0')
  const [isGradiant, setIsGradiant] = useState(false)

  let advanceStyle;
  if (!isGradiant) {
    advanceStyle = {
      background: color
    }
  } else {
    advanceStyle = {
      backgroundImage: `linear-gradient(to top right,#6e57e0,rgb(255, 94, 0))`
    }
  }


  return (
    <Layout>
      <Head content={`Contact`} />

      <div className="contact" style={{ paddingTop: '5rem' }}>

        <form action="#" className="form" style={advanceStyle}>
          <div className="color-span">
            <span className="color-span--1" onClick={() => {
              setColor('#6e57e0')
              setIsGradiant(false)
            }
            }>
            </span>
            <span className="color-span--2" onClick={() => {
              setColor('rgb(255, 94, 0)')
              setIsGradiant(false);
            }}></span>
            <span className="color-span--3" onClick={() => {
              setIsGradiant(true)
            }}></span>


            {/* <span className="color-span--3" onClick={() => setColor('#34495e')}></span> */}

            {/* <span className="color-span--6" onClick={()=> setColor('#1abc9c')}></span>
            <span className="color-span--7" onClick={()=> setColor('#e74c3c')}></span>
            <span className="color-span--8" onClick={()=> setColor('#9b59b6')}></span> */}

          </div>
          <h2>Contact me</h2>
          <div>
            <input type="text" className="form__input" placeholder="Full name" id="name" required />
            <label htmlFor="name" className="form__label">Full name</label>
          </div>
          <div>
            <input type="email" className="form__input" placeholder="Email address" id="email" required />
            <label htmlFor="email" className="form__label">Email address</label>
          </div>
          <div>
            <input type="text" className="form__input" placeholder="Want to say something!!" id="say" />
            <label htmlFor="say" className="form__label">Want to say something!!</label>
          </div>
          <button type="submit" style={{ color: color }}>
            Submit
          </button>
        </form>
      </div >
    </Layout >
  );
};
export default Contact;

{/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdB7w2CrC9rUgRxB2WdZW5Qfk6wRPToiXQIWAXDIOS5qYNmGw/viewform?embedded=true" width="640" height="800" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>  */ }